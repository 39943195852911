<template>
  <div>
    <sdPageHeader title="Добро пожаловать!">
      <template v-slot:buttons>
      </template>
    </sdPageHeader>
    <Main>

      <a-row :gutter="25">
        <a-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="16">
          <TaskListWrap class="mb-30">
            <div class="sDash_tasklist-wrap">
              <div class="sDash_tasklist-head">
                <h2 class="sDash_task-list-title">Чек-лист настройки платформы</h2>
              </div>
                <div class="sDash_tasklist-body">
                  <ul v-if="task.length > 0" class="sDash_tasks">
                    <li v-for="(item, i) in task" class="sDash_tasks-item" :key="i">
                      <div class="sDash_tasks-item__content">
                        <div class="sDash_tasks-item__title">
                          <a-checkbox
                            :checked="!!item.completed"
                            @change="() => dispatch('onCompleteUpdate', { data: task, id: item.id })"
                          >
                            {{ item.title }}
                          </a-checkbox>
                        </div>
                        <div class="sDash_tasks-item__text">
                          <p>{{ item.description }}</p>
                        </div>
                      </div>

                      <div class="sDash_tasks-item__action">
                        <router-link :to="item.link">
                          <a-button type="success" size="small">Выполнить</a-button>
                        </router-link>
                      </div>
                    </li>
                  </ul>

                  <div v-else class="sDash_empty-task">
                    <span>Sorry !! No Task Found :(</span>
                  </div>
                </div>
            </div>
          </TaskListWrap>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="7">
          <h2 class="sDash_task-list-title" style="font-size: 16px; font-weight: 500; padding: 15px 0px;">Возникли вопросы?</h2>

          <p>Напишите нам в Telegram и мы поможем разобраться в настройках платформы.</p>

          <a-button type="primary">Написать в Telegram</a-button>
        </a-col>

      </a-row>
    </Main>
  </div>
</template>
<script>
import { Main } from '../../../styled';
import { Figure3, BannerWrapper, Figure4, Figure5 } from '../style';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { TaskListWrap } from './task_style';

export default {
  name: 'InactiveHome',
  components: {
    Main, Figure3, BannerWrapper, Figure4, Figure5, TaskListWrap
  },
  setup() {

    const task = ref([
      {completed: false, id: 1, title: 'Настроить тарифы', description: 'Указать стоимость и период приобретения доступа в ваш канал.', link: '/settings/packages'},
      {completed: false, id: 2, title: 'Настроить чат-бот', description: 'Указать тексты сообщений приветствия пользователя, настроить кнопки в чат-боте', link: '/settings/chatbot'},
      {completed: false, id: 5, title: 'Создать прогрев', description: 'Добавить сообщения, которые будут отправляться для дожима', link: '/settings/flows'},
      {completed: false, id: 4, title: 'Подключить платежную систему', description: 'Поддерживаем YooMoney, YooKassa, Qiwi, Enot и др. системы', link: '/settings/payments'},
      {completed: false, id: 6, title: 'Активировать магазин', description: 'Отправить заявку на модерацию и начать принимать деньги', link: '/settings'}
    ]);

    return {
      task
    }
  }
};
</script>
<style lang="css">
  .sDash_tasks .sDash_tasks-item__title .ant-checkbox + span {
    color: #272B41 !important;
  }
</style>
