<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Настройка прогрева">
        <!-- <template #subTitle>
          <a-input v-model:value="searchValue" @change="handleSearch" placeholder="Search by Name">
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input>
        </template> -->
        <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Добавить сообщение</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="24">
        <sdCards headless>
          <UserTableStyleWrapper>
            <div class="contact-table">
              <TableWrapper class="table-responsive">
                <a-table
                  :dataSource="packagesTableData"
                  :columns="packagesTableColumns"
                />
              </TableWrapper>
            </div>
          </UserTableStyleWrapper>
        </sdCards>
      </a-col>
      <sdModal
        :type="localState.modalType"
        title="Добавить сообщение прогрева"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
                <a-form-item label="Текст сообщения" name="message">
                  <a-textarea v-model:value="addFormState.message" placeholder="Это сообщение будет отправлено пользователям бота" rows="6"/>
                </a-form-item>

                <a-form-item name="period" label="Через сколько отправить (с момента входа в бота)">
                  <a-input-group compact>
                    <a-input style="width: 30%" v-model:value="addFormState.send_at_value" placeholder="7"/>
                    <a-select v-model:value="addFormState.send_at_unit" style="width: 70%;">
                      <a-select-option value="minutes">минут</a-select-option>
                      <a-select-option value="hours">часов</a-select-option>
                      <a-select-option value="days">дней</a-select-option>
                    </a-select>
                  </a-input-group>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleOk" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Сохранить сообщение'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
      <sdModal
        :type="localState.modalType"
        title="Редактировать кнопку чат-бота"
        :visible="localState.editVisible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contactEdit" :rules="rules" :model="editFormState" ref="editFormRef" >
                <a-form-item label="Текст сообщения" name="message">
                  <a-textarea v-model:value="editFormState.message" placeholder="Это сообщение будет отправлено пользователям бота" rows="6"/>
                </a-form-item>

                <a-form-item name="period" label="Через сколько отправить (с момента входа в бота)">
                  <a-input-group compact>
                    <a-input style="width: 30%" v-model:value="editFormState.send_at_value" placeholder="7"/>
                    <a-select v-model:value="editFormState.send_at_unit" style="width: 70%;">
                      <a-select-option value="minutes">минут</a-select-option>
                      <a-select-option value="hours">часов</a-select-option>
                      <a-select-option value="days">дней</a-select-option>
                    </a-select>
                  </a-input-group>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleEditOk" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Сохранить сообщение'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>
  .ant-table-column-title {
    font-size: 14px !important;
  }
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';

import { periodUnits } from '@/config/enum';

const Flows = {
  name: 'Flows',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {
    const addFormState = reactive({
      message: '',
      send_at_unit: 'minutes',
      send_at_value: 5,
      id: ''
    });

    const editFormState = reactive({
      message: '',
      send_at_unit: '',
      send_at_value: '',
      id: ''
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(false);

    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      message: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        }
      ],
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.id = data.id;
      editFormState.message = data.messages[0].text;
      editFormState.send_at_unit = data.send_at_unit;
      editFormState.send_at_value = data.send_at_value;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const handleOk = values => {
      addFormRef.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios.post('/settings/flows/add', {
            project_id: activeProjectId(),
            message: addFormState.message,
            send_at_unit: addFormState.send_at_unit,
            send_at_value: addFormState.send_at_value
          }).then(response => {
            flowsList.value = response.data.flows;

            handleCancel();
          }).finally(() => {
            isLoading.value = false;
          });
        });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios.post('/settings/flows/edit', {
            project_id: activeProjectId(),
            id: editFormState.id,
            message: editFormState.message,
            send_at_unit: editFormState.send_at_unit,
            send_at_value: editFormState.send_at_value
          }).then(response => {
            flowsList.value = response.data.flows;

            handleCancel();
          }).finally(() => {
            isLoading.value = false;
          });
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onHandleDelete = id => {
      alert('Удаление сообщений пока недоступно, но мы работаем над этим. Вы можете удалить сообщения через техническую поддержку или просто отредактировать их.');
    };

    const flowsList = ref([]);
    const pkgs = computed(() => flowsList.value);

    const packagesTableData = computed(() => {
      return flowsList.value
        .sort((a, b) => {
          return a.unix_send_at - b.unix_send_at;
        })
        .map(pkg => {
          const { id, unix_send_at, messages, send_at_unit, send_at_value } = pkg;

          var send_at = '';

          if(send_at_unit == 'minutes')
            send_at = 'Через '+send_at_value+' мин. после старта';

          if(send_at_unit == 'hours')
            send_at = 'Через '+send_at_value+' ч. после старта';

          if(send_at_unit == 'days')
            send_at = 'Через '+send_at_value+' дн. после старта';


          return {
            key: id,
            id,
            send_at,
            actions: (<div style="white-space:normal;">{messages[0].text}</div>),
            action: (
              <Action class="table-actions">
                <sdDropdown
                  class="wide-dropdwon"
                  overlay={
                    <>
                      <a onClick={() => showEditModal(pkg, id)} to="#">
                        <span>Редактировать</span>
                      </a>
                      <a onClick={() => onHandleDelete(id)} to="#">
                        <span>Удалить</span>
                      </a>
                    </>
                  }
                  action={['click']}
                >
                  <sdButton class="btn-icon" type="info" to="#" shape="circle">
                    <sdFeatherIcons type="more-vertical" size={16} />
                  </sdButton>
                </sdDropdown>
              </Action>
            ),
          };
        });
    });

    axios.get('/settings/flows/list', {params: {project_id: activeProjectId()}})
    .then(response => {
      console.log(response);
      flowsList.value = response.data.flows;
    });

    const packagesTableColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Когда отправляется',
        dataIndex: 'send_at',
        key: 'send_at',
      },
      {
        title: 'Текст сообщения',
        dataIndex: 'actions',
        key: 'actions',
        width: '60%'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];


    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      packagesTableColumns,
      packagesTableData,
      flowsList,
      pkgs,

      rules,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading
    };
  },
};

export default Flows;
</script>
