<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Настройка кнопок чат-бота">
        <!-- <template #subTitle>
          <a-input v-model:value="searchValue" @change="handleSearch" placeholder="Search by Name">
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input>
        </template> -->
        <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Добавить команду</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="24">
        <sdCards headless>
          <UserTableStyleWrapper>
            <div class="contact-table">
              <TableWrapper class="table-responsive">
                <a-table
                  :dataSource="packagesTableData"
                  :columns="packagesTableColumns"
                />
              </TableWrapper>
            </div>
          </UserTableStyleWrapper>
        </sdCards>
      </a-col>
      <sdModal
        :type="localState.modalType"
        title="Добавить кнопку чат-бота"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contact" :rules="rules" :model="addFormState"  ref="addFormRef">
                <a-form-item label="Название (будет написано на кнопке)" name="name">
                  <a-input v-model:value="addFormState.name" placeholder="💰 Кнопка в боте" :disabled="addFormState.command_type == 'start'" />
                </a-form-item>

                <a-form-item label="Сообщение при нажатии на кнопку" name="message">
                  <a-textarea v-model:value="addFormState.message" placeholder="Это сообщение будет отправлено после того как пользователь нажмет на кнопку" rows="6"/>
                </a-form-item>

                <a-form-item label="Отображение кнопки" name="is_menu_shown">
                  <a-checkbox v-model:checked="addFormState.is_menu_shown">Показывать в меню чат-бота</a-checkbox>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleOk" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Сохранить команду'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
      <sdModal
        :type="localState.modalType"
        title="Редактировать кнопку чат-бота"
        :visible="localState.editVisible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form name="contactEdit" :rules="rules" :model="editFormState" ref="editFormRef" >
                <a-form-item label="Название (будет написано на кнопке)" name="name">
                  <a-input v-model:value="editFormState.name" placeholder="💰 Кнопка в боте" :disabled="editFormState.command_type == 'start' || editFormState.command_type == 'pay_success' || editFormState.command_type == 'expired'" />
                </a-form-item>

                <a-form-item label="Сообщение при нажатии на кнопку" name="message">
                  <a-textarea v-model:value="editFormState.message" placeholder="Это сообщение будет отправлено после того как пользователь нажмет на кнопку" rows="6"/>
                </a-form-item>


                <a-form-item label="Отображение кнопки" name="is_menu_shown" v-if="editFormState.command_type == 'custom' || editFormState.command_type == 'pay'">
                  <a-checkbox v-model:checked="editFormState.is_menu_shown">Показывать в меню чат-бота</a-checkbox>
                </a-form-item>

                <sdButton htmlType="submit" size="default" type="primary" key="submit" @click="handleEditOk" :disabled="isLoading">
                  {{ isLoading ? 'Загрузка...' : 'Сохранить команду'}}
                </sdButton>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<style lang="css" scoped>
  .ant-table-column-title {
    font-size: 14px !important;
  }
</style>
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect, reactive } from 'vue';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import axios from '@/config/axios'
import activeProjectId from '@/config/helpers'
import { SpinerWraperStyle } from '@/view/uiElements/ui-elements-styled';

import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from '@/view/apps/contact/style';
import { Main, CardToolbox, BasicFormWrapper, TableWrapper } from '@/view/styled';
import { AddUser } from '@/view/pages/style';

import { periodUnits } from '@/config/enum';

const Chatbot = {
  name: 'Chatbot',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  setup() {
    const addFormState = reactive({
      name: '',
      message: '',
      command_type: 'custom',
      is_menu_shown: true,
      id: ''
    });

    const editFormState = reactive({
      name: '',
      message: '',
      command_type: 'custom',
      is_menu_shown: true,
      id: ''
    });

    const editFormRef = ref();
    const addFormRef = ref();

    const isLoading = ref(false);

    const rules = {
      name: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 200,
          message: 'Минимальная длина поля - 3 символа',
          trigger: 'blur',
        }
      ],
      message: [
        {
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur',
        }
      ],
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: 'primary',
      url: null,
    });

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = data => {
      localState.editVisible = true;
      editFormState.name = data.name;
      editFormState.id = data.id;
      editFormState.message = data.messages[0].text;
      editFormState.command_type = data.command_type;
      editFormState.is_menu_shown = (data.is_menu_shown == 1);
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const handleOk = values => {
      addFormRef.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios.post('/settings/chatbot/add', {
            project_id: activeProjectId(),
            name: addFormState.name,
            message: addFormState.message,
            is_menu_shown: addFormState.is_menu_shown,
          }).then(response => {
            packagesList.value = response.data.packages;

            handleCancel();
          }).finally(() => {
            isLoading.value = false;
          });
        });
    };

    const handleEditOk = values => {
      console.log('handle edit ok');

      editFormRef.value
        .validate()
        .then(() => {
          isLoading.value = true;

          axios.post('/settings/chatbot/edit', {
            project_id: activeProjectId(),
            id: editFormState.id,
            name: editFormState.name,
            message: editFormState.message,
            is_menu_shown: editFormState.is_menu_shown,
          }).then(response => {
            packagesList.value = response.data.packages;

            handleCancel();
          }).finally(() => {
            isLoading.value = false;
          });
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onHandleDelete = id => {
      alert('Удаление кнопок пока недоступно, но мы работаем над этим. Вы можете удалить кнопки через техническую поддержку или просто отредактировать их.');
    };

    const packagesList = ref([]);
    const pkgs = computed(() => packagesList.value);

    const packagesTableData = computed(() => {
      return packagesList.value
        .sort((a, b) => {
          return a.price - b.price;
        })
        .map(pkg => {
          const { id, name, command_type, messages, is_menu_shown, project_id } = pkg;

          var type = 'Пользовательская команда';

          if(command_type == 'start')
            type = 'Стартовое сообщение (при открытии бота)';

          if(command_type == 'pay')
            type = 'Кнопка для оплаты подписки (нельзя удалить)';

          if(command_type == 'pay_success')
            type = 'Сообщение после успешной оплаты (нельзя удалить)';

          if(command_type == 'expired')
            type = 'Сообщение об истечении подписки (нельзя удалить)';

          return {
            key: id,
            name: (
              <div>
                {name}<br/>
                <small style="color: #9a9a9a;">{type}</small>
              </div>
            ),
            is_menu_shown: (is_menu_shown) ? (<div>Да</div>) : (<div>Нет</div>),
            actions: (<div style="white-space:normal;">{messages[0].text}</div>),
            action: (
              <Action class="table-actions">
                <sdDropdown
                  class="wide-dropdwon"
                  overlay={
                    <>
                      <a onClick={() => showEditModal(pkg, id)} to="#">
                        <span>Редактировать</span>
                      </a>
                      <a onClick={() => onHandleDelete(id)} to="#">
                        <span>Удалить</span>
                      </a>
                    </>
                  }
                  action={['click']}
                >
                  <sdButton class="btn-icon" type="info" to="#" shape="circle">
                    <sdFeatherIcons type="more-vertical" size={16} />
                  </sdButton>
                </sdDropdown>
              </Action>
            ),
          };
        });
    });

    axios.get('/settings/chatbot/list', {params: {project_id: activeProjectId()}})
    .then(response => {
      console.log(response);
      packagesList.value = response.data.commands;
    });

    const packagesTableColumns = [
      {
        title: 'Команда',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Сообщение при нажатии на кнопку',
        dataIndex: 'actions',
        key: 'actions',
        width: '40%'
      },
      {
        title: 'Показывать в меню',
        dataIndex: 'is_menu_shown',
        key: 'is_menu_shown',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '90px',
      },
    ];


    return {
      onCancel,
      showEditModal,
      showModal,
      localState,

      handleCancel,
      handleEditOk,
      handleOk,

      packagesTableColumns,
      packagesTableData,
      packagesList,
      pkgs,

      rules,
      addFormRef,
      addFormState,
      editFormRef,
      editFormState,

      isLoading
    };
  },
};

export default Chatbot;
</script>
